@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

@font-face {
  font-family: "helv-bold";
  src: url("./assets/fonts/helv-bold.otf")
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

q {

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.app-header {
  position: fixed;
  width: 100%;
  top: 0;
  background: #fafbfc;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 100;
  transition: all .2s;

  .app-header__logo {
    padding: 0 1.5rem;
    height: 60px;
    width: 280px;
    display: flex;
    align-items: center;
    transition: width .2s;
    background: rgba(250, 251, 252, 0.1);
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 1.5rem;
    height: 60px;

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;

      .header-btn-lg {
        padding: 0 0 0 1.5rem;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
        position: relative;

        &:before {
          position: absolute;
          left: -1px;
          top: 50%;
          background: #dee2e6;
          width: 1px;
          height: 30px;
          margin-top: -15px;
          content: '';
        }

        button {
          background: no-repeat;
          border: none;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.app-sidebar {
  position: fixed;
  height: 100vh;
  background: #fff;
  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  width: 280px;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: 280px;
  flex: 0 0 280px;
  padding-top: 75px;
  transition: all .2s;
.accardion-title {
  font-family: 'Roboto', sans-serif;
}
  .app-sidebar__inner {
    padding: 2px 0.5rem 1.5rem;

    .vertical-nav-menu {
      margin: 0;
      padding: 0;
      position: relative;
      list-style: none;
      margin-bottom: 20px;
    }

    .app-sidebar__heading {
      font-size: .8rem;
      margin: .75rem 0;
      font-weight: bold;
      color: #002c5e;
      white-space: nowrap;
      position: relative;
      font-family: 'Roboto', sans-serif;
      text-decoration: none;
    }

    a {
      display: block;
      line-height: 1.5;
      height: auto;
      padding: 0 1.5rem 0 15px;
      position: relative;
      border-radius: .25rem;
      color: #343a40;
      white-space: nowrap;
      transition: all .2s;
      margin: .1rem 0;
      font-family: 'Roboto', sans-serif;
      text-decoration: none;
      font-size: 14px;
    }
  }
}

.langs {
  button {
    margin-left: 10px;
    padding: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background-color: #002c5e;
      color: #fff;
    }
  }
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  width: 5px !important;
  right: 5px !important;
}

.ScrollbarsCustom-Track {
  opacity: 0;
}

.ScrollbarsCustom {
  &:hover {
    .ScrollbarsCustom-Track {
      opacity: 1;
    }
  }
}

.content-wrapper {
  margin-left: 280px;
  overflow: hidden;
  padding: 80px 15px 0;
  min-height: 100vh;
  transition: all .3s ease-out 0s;

  .box-item {
    padding: 25px;
    min-height: 250px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 15px;

    .box-item-header {
      width: 100%;
      padding-bottom: 15px;

      h3 {
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        color: #000;
      }
    }
  }
}

.tab-item {
  &.two-buttons {
    header .MuiButtonBase-root {
      width: calc(100% / 2);
      max-width: unset;
    }
  }

  .MuiButtonBase-root {
    color: #000;
    background-color: #f3f3f3;
  }

  .MuiAppBar-colorPrimary {
    background-color: #f3f3f3;
  }

  .MuiTabs-indicator {
    background-color: #0077ff;
  }

  .tab-content {
    padding: 25px 0 0
  }
}

.input-item {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;

  .MuiFormControl-root {
    width: 100%;
  }
}

.upload-area {
  display: inline-block;
  width: 100%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  .dropzone {
    text-align: center;
    padding: 15px;
    cursor: pointer;

    p {
      font-family: 'Roboto', sans-serif;
    }
  }

  .file-list {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0;
    overflow-x: auto;

    .media-item {
      min-width: 150px;
      position: relative;
      height: 150px;
      // background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
      padding: 5px;
      overflow: hidden;
      .remove-btn {
        position: absolute;
    top: 0;
    right: 0;
    background: red;
    border-radius: 100%;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
      }
    }

    img {
      height: 115px;
    }
  }
}

.btn-row {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;

  .MuiButton-containedPrimary {
    color: #fff !important;
    background-color: #009688 !important;
  }
}

.table-item {
  .rdt_TableHead {
    *:not(.material-icons) {
      font-family: 'helv-bold' !important;

    }
  }

  .rdt_TableRow {
    *:not(.material-icons) {
      font-family: 'helv-bold' !important;
    }
  }

  .slide {
    width: 100px;
    height: 50px;
    margin: 5px 0;

    .img {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .menu-item {
    position: relative;
    button {
      width: 40px !important;
      height: 40px;
      margin: 0 5px;
      color: #fff;

      &.edit-btn {
        background-color: #FF9800;
      }

      &.delete-btn {
        background-color: red;
      }
    }
  }
  .vert-menu-btn {
    min-width: 40px;
    background: #002c5e;
  }
  
  .popover-item {
    position: absolute;
    background: #fff;
    display: flex;
    padding: 10px;
    z-index: 3;
    right: 45px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0,  0.15);
    top: -11px;
    display: none;
  }
  .menu-item:hover  .popover-item {
    display: flex;
  }
}

.MuiToolTip-Popper {
  * {
    font-family: 'helv-bold'    !important;
  }
}

.auth-container {
  height: 100vh;

  .h-100 {
    height: 100% !important;
    background-image: url("https://emilus.themenate.net/img/others/img-17.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .container {
    display: flex;
    width: 500px;
    margin: 0 auto;
  }

  .ant-row-center {
    -ms-flex-pack: center;
    justify-content: center;
  }

  .ant-row,
  .ant-row:after,
  .ant-row:before {
    display: -ms-flexbox;
    display: flex;
  }

  .ant-row {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .ant-col-xs-20 {
    display: block;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }

  .ant-card-bordered {
    border: 1px solid #edf2f9;
  }

  .ant-card {
    background: #fff;
    border-radius: .625rem;
    margin-bottom: 1.25rem;
  }

  .ant-card,
  .ant-carousel {
    font-size: .875rem;
    line-height: 1.5;
    color: #455560;
  }

  .ant-card {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
  }

  .ant-card-body {
    padding: 1.25rem;
  }

  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }

  .text-center {
    text-align: center !important;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .ant-col-xs-24 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }

  .ant-form {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
  }

  .ant-form-item {
    font-size: .875rem;
    line-height: 1.5;
    color: #455560;
    margin: 0 0 24px;
  }

  .ant-form-vertical .ant-form-item {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ant-form-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    margin: 0 0 24px;
    vertical-align: top;
  }

  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label,
  .ant-form-vertical .ant-form-item-label {
    line-height: 1.5;
    padding-bottom: .35rem;
  }

  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label,
  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    text-align: left;
  }

  .ant-form-item-label {
    color: #455560;
  }

  .ant-form-item-label {
    display: inline-block;
    -ms-flex-positive: 0;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }

  .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }

  .ant-col-24.ant-form-item-label>label,
  .ant-col-xl-24.ant-form-item-label>label,
  .ant-form-vertical .ant-form-item-label>label {
    height: auto;
    width: 100%;
  }

  .ant-col-24.ant-form-item-label>label,
  .ant-col-xl-24.ant-form-item-label>label,
  .ant-form-vertical .ant-form-item-label>label {
    margin: 0;
  }

  .ant-form-item-label>label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
  }

  .ant-form-item-label label {
    color: #455560;
    font-weight: 500;
    height: 2.5125rem;
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .ant-form-item-control {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .ant-form-item-control-input {
    min-height: 2.5125rem;
  }

  .ant-form-item-control-input {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: 32px;
  }

  .ant-form-item-control-input-content {
    -ms-flex: auto;
    flex: auto;
    max-width: 100%;
  }

  .ant-input-affix-wrapper {
    padding: .6rem 1rem;
    color: #455560;
    font-size: .875rem;
    line-height: 1.5;
    background-color: #fff;
    border: 1px solid #edf2f9;
    border-radius: .625rem;
    max-height: none;
  }

  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: calc(100% - 25px);
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .ant-input-affix-wrapper:before {
    width: 0;
    visibility: hidden;
    content: "\a0";
  }

  .ant-input-prefix {
    margin-right: .625rem;
  }

  .ant-input-prefix,
  .ant-input-suffix {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: none;
    flex: none;
    -ms-flex-align: center;
    align-items: center;
  }

  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-input-affix-wrapper>input.ant-input {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0;
    width: 100%;
    height: 40px
  }

  .ant-btn {
    font-size: .875rem;
    padding: .5rem 1.25rem;
    line-height: 1.5;
    border-color: #edf2f9;
    color: #455560;
    height: 2.5rem;
    border-radius: .625rem;
    cursor: pointer;
  }

  .ant-btn-primary {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
  }

  .ant-btn-block {
    width: 100%;
  }

  .ant-btn-primary {
    background-color: #3e82f7;
    border-color: #3e82f7;
    color: #fff;
  }
}

.select-item__menu {
  z-index: 99999 !important;
  font-family: 'helv-bold';
}
.select-item__value-container {
  font-family: 'helv-bold';
}
.select-item__single-value  {
  line-height: normal;
}

.metismenu .active-link {
  color: #005dc7 !important
} 
 
.MuiButtonBase-root.Mui-disabled {
  opacity: 0.5;
}
label {
  font-family: 'Roboto', sans-serif;
}

[data-tag="___react-data-table-allow-propagation___"] {
  display: flex;
  overflow-x: auto !important; 
  min-width: 300px;
  max-width: 300px;

  .slide {
    min-width: 100px;
  }
}
.select__menu {
  z-index: 9 !important
}
.select__single-value  {
  font-family: 'Roboto', sans-serif;
}
.table-item {
  font-family: 'Roboto', sans-serif;
}
.select__placeholder, .select__option {
  font-family: 'Roboto', sans-serif;
}